<template>
  <div class="company-intro">
    <el-divider content-position="left">会社概要</el-divider>
    <table class="summary-table">
      <tr>
        <td>会社名</td>
        <td>株式会社ジャンプソフト</td>
      </tr>
      <tr>
        <td>英文社名</td>
        <td>Jump Soft</td>
      </tr>
      <tr>
        <td>社名略称</td>
        <td>JPS</td>
      </tr>
      <tr>
        <td>会社設立日</td>
        <td>2021/4/26</td>
      </tr>
      <tr>
        <td>資本金</td>
        <td>500万円</td>
      </tr>
      <tr>
        <td>代表取締役社長</td>
        <td>柳 恩宝</td>
      </tr>
      <tr>
        <td>本社所在地</td>
        <td>
          〒113-0034<br />
          東京都文京区湯島２－２－１<br />
          東邦深澤ビル３F-C号室
        </td>
      </tr>
      <tr>
        <td>TEL</td>
        <td>03-3527-3483</td>
      </tr>
      <tr>
        <td>FAX</td>
        <td>03-3527-3484</td>
      </tr>
      <tr>
        <td>取引銀行</td>
        <td>三菱UFJ銀行</td>
      </tr>
      <tr>
        <td>事業概要</td>
        <td>
          <ul>
            <li>ITエンジニアの派遣、紹介及び育成</li>
            <li>ITシステムの設計、施工、保守及び管理</li>
            <li>ITに関する開発、構築、及びコンサルティング業務</li>
            <li>前各号に付帯関連する一切の事業</li>
          </ul>
        </td>
</tr>
    </table>
    <el-divider content-position="left">アクセス</el-divider>
    <table class="access-list">
      <tr>
        <td><li>東京メトロ 丸の内線</li></td>
        <td>御茶ノ水駅</td>
        <td>２番出口より徒歩８分</td>
      </tr>
      <tr>
        <td><li>ＪＲ東 総武線</li></td>
        <td>御茶ノ水駅</td>
        <td>聖橋口より徒歩８分</td>
      </tr>
    </table>
    <el-divider content-position="left">地図</el-divider>
    <el-card class="map-card" :body-style="{ padding: '1px' }">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            width="100%"
            height="500"
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25918.741538216178!2d139.74892730000002!3d35.7054885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c188c51cf57%3A0x46c166fd07c48b8f!2z44CSMTEzLTAwMzQg5p2x5Lqs6YO95paH5Lqs5Yy65rmv5bO277yS5LiB55uu77yS4oiS77yRIOadsemCpua3sea-pOODk-ODqw!5e0!3m2!1sja!2sjp!4v1684307434413!5m2!1sja!2sjp"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          />
          <a href="https://putlocker-is.org" />
        </div>
      </div>
    </el-card>
  </div>
</template>

<style lang="sass" scoped>
.company-intro
  max-width: 1200px
  margin: auto
  padding: 1px 24px
  :deep(.el-divider__text)
    font-weight: bold
    font-size: 18px
    user-select: none
  .summary-table
    margin: auto
    width: 100%
    max-width: 600px
    border-collapse: collapse
    td
      border: 1px solid lightgray
      font-size: 13px
      padding: 6px 9px
    td:first-child
      width: 30%
      min-width: 100px
      font-weight: bold
      background-color: #fafafa
  .access-list
    width: fit-content
    margin: auto
    padding-right: 100px
    @media (orientation: portrait)
      padding-right: 0px
    td
      font-size: 13px
      white-space: nowrap
      @media (orientation: portrait)
        padding-right: 8px!important
    td:first-child
      padding-right: 12px
    td:not(first-child)
      padding-right: 64px
  .map-card
    width: 600px
    margin: 24px auto
    @media (orientation: portrait)
      width: 100%
      overflow-x: scroll
    .mapouter
      position: relative
      text-align: right
      height: 500px
      width: 100%
    .gmap_canvas
      overflow: hidden
      background: none !important
      height: 500px
      width: 100%
</style>